import React from "react"
import { Layout } from "../components/Layout"
import { Seo } from "../components/Seo"
import Container from "../components/Container"

const CookiePage = () => {
  return (
    <Layout>
      <Container>
        <main className="mx-auto mt-24 max-w-screen-lg tracking-normal md:mt-32">
          <header>
            <h1 className="text-2xl font-bold">Cookie Policy</h1>
          </header>
          <p className="text-cream-300 my-4 text-base">
            Our website uses cookies to enhance your user experience and to help us analyze and
            improve our website. In this cookie policy, we explain which cookies we use, why we use
            them, and how you can manage them.
          </p>
          <h2 className="my-4 text-lg font-bold sm:text-xl">What are cookies?</h2>
          <p className="text-cream-300 my-4 text-base">
            Cookies are small text files that a website places on your computer or mobile device
            when you visit the website. Cookies are used to collect information about your visit to
            the website, such as your preferences and browsing history.
          </p>
          <h2 className="my-4 text-lg font-bold sm:text-xl">What cookies do we use?</h2>
          <p className="text-cream-300 my-4 text-base">
            Our website only uses cookies from Google Analytics. These cookies are used to collect
            aggregated statistics about the use of our website, such as the number of visitors and
            which pages are most visited. We use this information to analyze and improve our
            website. The cookies do not collect any personal information. Also we have configured
            Google Analytics to anonymize the IP addresses of visitors to our website before storing
            or processing any data.
          </p>
          <h2 className="my-4 text-lg font-bold sm:text-xl">How can you manage cookies?</h2>
          <p className="text-cream-300 my-4 text-base">
            If you do not want cookies to be placed, you can adjust your browser settings to block
            the placement of cookies. However, please note that blocking cookies may affect the
            functionality of our website.
          </p>
          <p className="text-cream-300 my-4 text-base">
            You can also choose to disable Google Analytics cookies by downloading and installing
            the Google Analytics Opt-out Browser Add-on. This will prevent your data from being
            collected and used by Google Analytics.
          </p>
          <h2 className="my-4 text-lg font-bold sm:text-xl">
            What happens after I click 'Accept'?
          </h2>
          <p className="text-cream-300 my-4 text-base">
            Only after you have chosen to accept the cookies will they be placed in your browser.
            When you click on nothing, nothing will happen.
          </p>
          <h2 className="my-4 text-lg font-bold sm:text-xl">
            What happens after I click 'Decline'?
          </h2>
          <p className="text-cream-300 my-4 text-base">
            The cookie banner will be removed and nothing will be placed in your browser.
          </p>
          <h2 className="my-4 text-lg font-bold sm:text-xl">
            What happens when I click on neither?
          </h2>
          <p className="text-cream-300 my-4 text-base">
            No cookies will be placed in your browser. However, you will continue to see the
            annoying cookie banner."
          </p>
        </main>
      </Container>
    </Layout>
  )
}

export default CookiePage

export const Head = () => <Seo title="Cookie Policy" noIndex={true}></Seo>
