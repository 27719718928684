import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"

function Seo({ title, description, image, pathname, children, noIndex }) {
  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl,
    image: defaultImage,
    twitterUsername
  } = useSiteMetadata()

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image ? `${siteUrl}${image}` : `${siteUrl}${defaultImage}`,
    url: `${siteUrl}${pathname || ``}`,
    twitterUsername
  }

  return (
    <>
      <title>{seo.title}</title>
      {noIndex && <meta name="robots" content="noindex" />}
      <meta name="language" content="en" />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <link rel="canonical" href={seo.url} />
      <meta name="og:title" property="og:title" content={seo.title} />
      <meta name="og:url" property="og:url" content={seo.url} />
      <meta name="og:description" property="og:description" content={seo.description} />
      <meta name="og:image" property="og:image" content={seo.image} />
      <meta name="og:type" property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:image:alt" content=""></meta>
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <meta name="twitter:site" content={seo.twitterUsername} />
      {children}
    </>
  )
}

export { Seo }
